import React, { useEffect, useState } from "react";
import '../Assets/LandingPage.css'
import '../Assets/Howitworks.css'
import Home from '../Materials/image.png'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import AI2Logo from '../Materials/AI2Logow.png'
import AI2LogoB from '../Materials/AI2Logoq.png'

const HowItWorks = () => {
    const [open, setOpen] = useState(false);
    const [highlight,setHighlight] = useState('fencing?')
    const [navBg, setNavBg] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        window.addEventListener('scroll', changeNavColor);
        return () => {
          window.removeEventListener('scroll', changeNavColor);
        }
      }, [])
    const changeNavColor = () =>
        {
         window.scrollY >= 100 ? setNavBg(true) : setNavBg(false);
        }
    React.useEffect(() => {
            window.scrollTo(0, 0)
          }, [])
    return (
        <div>
            <div className="bg-image-how">
            <div className={navBg ? "header-scroll":"header"}>
            <div className="logo" onClick={()=>navigate('/')}>{navBg ? <img style={{height:'9Vh'}} src={AI2Logo} alt='AI2'/>:<img src={AI2LogoB} alt='AI2'/>}</div>
            <nav>
                <ul>
                <li onClick={()=>navigate('/how-it-works')}>How it Works</li>
                <li onClick={()=>navigate('/pricing')}>Pricing</li>
                <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                </ul>
            </nav>
            <button className="login-btn">Login</button>
            </div>
            <div className="how-it-works-banner">
            <h4>BUILD ANYTHING</h4>
            <h1>One tool for any<br/> home<br/> construction<br/> project</h1>
            <p>Whether it's a new roof or just a fence repair, our powerful buying experience walks you through, step by step.</p>
            <button className="how-it-works-button">Start your project today &nbsp;&nbsp;<i class="bi bi-arrow-right"></i></button>
            </div>
            </div>
            <div className="how-it-works-black">
            <h1>Direct access to<br/> materials and labor</h1>
            <p>Our simple to use tool helps you plan, coordinate and pay for your project all<br/> without the middleman. Get direct access to at cost materials and labor in<br/> your area, saving you thousands.</p>
            <button className="how-it-works-button-borderless">PRICING INFO &nbsp;&nbsp;<i class="bi bi-arrow-right"></i></button><button className="how-it-works-button-borderless">GET STARTED &nbsp;&nbsp;<i class="bi bi-arrow-right"></i></button>
            </div>
            <div className="how-it-works-black-next">
            <div className="how-it-works-black-next-items-left">
              <div className="how-it-works-black-next-items-child">
              <h4>Select your service</h4>
              <p>Get started with Glas in one click. Choose your service and we will set up your project to your specifications.</p>
              </div>
              <div className="how-it-works-black-next-items-child">
              <h4>Plan your project</h4>
              <p>Based on your project, we help collect all the necessary details like measurements and customizations for each construction trade.</p>
              </div>
              <div className="how-it-works-black-next-items-child">
              <h4>Select materials and labor</h4>
              <p>This is where things get special. Glas helps you find the best materials for your project and ensure they are installed by a high quality crew.</p>
              </div>
            </div>
            <div className="how-it-works-black-next-items">
              <div className="how-it-works-black-next-items-child">
              <img src={Home} className="how-it-works-black-next-items-img"/>
              </div>
            </div>
            <div className="how-it-works-black-next-items-right">
              <div className="how-it-works-black-next-items-child">
              <h4>Pay your deposit</h4>
              <p>To start your project, we help you make your first deposit. Rest assured, we hold your payment in escrow until the job is completed. Satisfaction is our number one priority.</p>
              </div>
              <div className="how-it-works-black-next-items-child">
              <h4>Get installed</h4>
              <p>The time has come! Sit back and relax as your project is completed. All the details are handled seamlessly by Glas.</p>
              </div>
              <div className="how-it-works-black-next-items-child">
              <h4>Final walkthrough</h4>
              <p>After receiving final sign-off from Glas, you can give your vote of approval. Then we help finalize the completion paperwork and remedy any final payments.</p>
              </div>
            </div>
            </div>
            <div className="how-it-works-white">
             <div className="how-it-works-white-items">Ready to cut out the middleman?</div>
             <div className="how-it-works-white-items"><p>Starting a project is as simple as a few questions. Then we take you through estimating, installation and payment.<br/><button className="how-it-works-white-button">Start your project today &nbsp;&nbsp;<i class="bi bi-arrow-right"></i></button></p></div>
            </div>
            <div className="how-it-works--sections-container">           
            <div className="how-it-works--sections">
            <h1>Still have<br/> some<br/> questions?</h1>
            </div>
            <div className="collabse-container">
            <>
            <Button
              onClick={() => setOpen(1)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="collabse-button"
            >
              What are the average savings?
            </Button>
            <Collapse in={open==1}>
              <div id="collabse">
              With Glas having a fixed markup fee of 15%, that is between a quarter to half of the industry average markup. We are able to offer these low prices by digitizing the process. We give you direct access to installation crews and material pricing. You will always know those costs and exactly what we make.
              </div>
            </Collapse>
            <Button
              onClick={() => setOpen(2)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="collabse-button"
            >
              How long does the average project take?
            </Button>
            <Collapse in={open==2}>
              <div id="collabse">
              Glas will help you coordinate your entire job from planning to final payment. Depending on the project type and if you are using insurance proceeds to pay for your project, you can expect between 3 days to 2 weeks. Rest assured, Glas helps you coordinate the project as fast or as slow as you want to go.
              </div>
            </Collapse>
            <Button
              onClick={() => setOpen(3)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="collabse-button"
            >
              I'm worried about paying before install?
            </Button>
            <Collapse in={open==3}>
              <div id="collabse">
              If you've ever completed a construction project on your home, a deposit was always requested. This can be concerning because you aren't sure if the job will be completed correctly. With Glas, we put your deposit in an escrow account and guarantee the completion of the project to the original specifications. Your money is only released to the installation crew upon final sign-off from Glas.
              </div>
            </Collapse>
            <Button
              onClick={() => setOpen(4)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="collabse-button"
            >
              What If I have certain specifications for my project?
            </Button>
            <Collapse in={open==4}>
              <div id="collabse">
              Glas is built to help you plan, coordinate and execute on your project according to your specifications. If you have any special requests, we seamlessly handle those in the planning phase and ensure they are carried out during the installation.
              </div>
            </Collapse>
            </>
            </div>
            <div className="collabse-center-container">
              <div className="collabse-center-container-someone">
              <h1>Need to talk to someone?</h1>
              <p>Chat with a member of our support team</p>
              </div>
              <div className="collabse-center-container-button">
              <button className="collabse-center-container-button-inside">Connect Now &nbsp;&nbsp;<i class="bi bi-arrow-right"></i></button>
              </div>
            </div>
            </div>
            <div className="footer">
            <div className="footer-list">
                <ul>
                    <li>Resources</li>
                    <li>Blog</li>
                    <li>Make a Payment</li>
                </ul>
            </div>
            <div className="footer-logo"><img src={AI2Logo}/></div>
            <div className="footer-list">
            <ul>
                    <li onClick={()=>navigate('/Support')}>Support</li>
                    <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                    <li onClick={()=>navigate('/TermsAndConditions')}>Terms and Conditions</li>
                </ul>
            </div>
            </div>
            <div className="disclaimer">© AI2Claim 2024</div>
        </div>
    )
}

export default HowItWorks;