import '../Assets/PrivacyPolicy.css'; 
import React, { useEffect, useState } from "react";
import AI2Logo from '../Materials/AI2Logow.png'
import { useNavigate } from "react-router-dom";
import AI2LogoB from '../Materials/AI2Logoq.png'



const PrivacyPolicy = () => {
  const [highlight,setHighlight] = useState('fencing?')
  const [navBg, setNavBg] = useState(false);
  const navigate = useNavigate()
  useEffect(()=>{
   changeText();
  },[])
  
  React.useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  const changeText = () => {
      let x = ['gutters?', 'windows?', 'roof?','fencing?'];
      let index = 0;
  
      setInterval(() => {
          setHighlight(x[index]);
          index = (index + 1) % x.length;
      }, 4000);
  };
  useEffect(() => {
      window.addEventListener('scroll', changeNavColor);
      return () => {
        window.removeEventListener('scroll', changeNavColor);
      }
    }, [])
  const changeNavColor = () =>
      {
      }

  return (
    <div className='privacy-body'>
  <div className="pricing">
     <div className={navBg ? "header-scroll":"header"}>
            <div className="logo" onClick={()=>navigate('/')}>{navBg ? <img style={{height:'9Vh'}} src={AI2LogoB} alt='AI2'/>:<img src={AI2LogoB} alt='AI2'/>}</div>
            <nav>
                <ul>
                <li onClick={()=>navigate('/how-it-works')}>How it Works</li>
                <li onClick={()=>navigate('/pricing')}>Pricing</li>
                <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                </ul>
            </nav>
            <button className="login-btn">Login</button>
            </div> 
           
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="privacy-policy">
      <h1>Privacy Policy for AI2Claim App</h1>
      <p className='Privacy-Last'>Last Updated: Aug 23, 2024</p>
      
      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to AI2Claim. This Privacy Policy outlines how we collect, use, disclose, 
          and safeguard your information when you use our mobile application, AI2Claim (the "App"). 
          Please read this Privacy Policy carefully. By using the App, you consent to the practices 
          described in this policy.
        </p>
      </section>
      
      <section>
        <h2>1. Information We Collect</h2>
        <h3>1.1. Personal Information</h3>
        <p>
          We may collect personal information that you voluntarily provide when you use our App. 
          This includes, but is not limited to:
        </p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Address</li>
          <li>Insurance details</li>
          <li>Claim details and history</li>
        </ul>
        
        <h3>1.2. Automatically Collected Information</h3>
        <p>
          When you use the App, we may automatically collect information about your device and 
          usage of the App, including:
        </p>
        <ul>
          <li>Device Information: IP address, device type, operating system, and unique device identifiers.</li>
          <li>Usage Data: Time and duration of use, pages viewed, and other usage statistics.</li>
          <li>Location Information: We may collect location data if you enable location services on your device.</li>
        </ul>
      </section>
      
      <section>
        <h2>2. How We Use Your Information</h2>
        <p>We may use the information we collect for various purposes, including:</p>
        <ul>
          <li>To provide, operate, and maintain the App.</li>
          <li>To process and manage your claims.</li>
          <li>To communicate with you about your claims and provide customer support.</li>
          <li>To send you updates, notifications, and promotional materials, subject to your preferences.</li>
          <li>To improve our services, develop new features, and enhance user experience.</li>
          <li>To monitor and analyze usage and trends to improve the App.</li>
          <li>To comply with legal obligations and protect our rights.</li>
        </ul>
      </section>

      <section>
        <h2>3. Sharing Your Information</h2>
        <p>We may share your information in the following circumstances:</p>
        <ul>
          <li>With Service Providers: We may share your information with third-party service providers who assist us in operating the App, processing claims, and providing customer support.</li>
          <li>With Insurance Providers: We may share claim-related information with your insurance company or other relevant parties to process your claims.</li>
          <li>Legal Requirements: We may disclose your information if required by law, regulation, or legal process, or if we believe such disclosure is necessary to protect our rights or comply with legal obligations.</li>
          <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
        </ul>
      </section>

      <section>
        <h2>4. Your Choices</h2>
        <h3>4.1. Account Information</h3>
        <p>You may update, correct, or delete your account information at any time by accessing your account settings in the App.</p>
        
        <h3>4.2. Communications</h3>
        <p>You may opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications or by contacting us directly.</p>
        
        <h3>4.3. Location Data</h3>
        <p>You can control the collection of location data through your device’s settings.</p>
      </section>

      <section>
        <h2>5. Security of Your Information</h2>
        <p>
          We implement reasonable security measures to protect your information from unauthorized access, 
          use, or disclosure. However, no method of transmission over the internet or method of electronic 
          storage is completely secure. Therefore, while we strive to use commercially acceptable means to 
          protect your information, we cannot guarantee its absolute security.
        </p>
      </section>

      <section>
        <h2>6. Children’s Privacy</h2>
        <p>
          The App is not intended for use by children under the age of 13, and we do not knowingly collect 
          personal information from children under 13. If we become aware that we have collected personal 
          information from a child under 13, we will take steps to delete such information.
        </p>
      </section>

      <section>
        <h2>7. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. If we make significant changes, we will 
          notify you by posting a notice in the App or by other means. Your continued use of the App after 
          such changes will constitute your acknowledgment of the modified policy.
        </p>
      </section>

      <section>
        <h2>8. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy or our data practices, please contact us at:
        </p>
        <ul>
          <li>Email: name@gmail.com</li>
          <li>Phone: 12346789</li>
          <li>Address: USA</li>
        </ul>
      </section>
    </div>
    <div className="footer">
            <div className="footer-list">
                <ul>
                    <li>Resources</li>
                    <li>Blog</li>
                    <li>Make a Payment</li>
                </ul>
            </div>
            <div className="footer-logo"><img src={AI2Logo}/></div>
            <div className="footer-list">
            <ul>
                    <li onClick={()=>navigate('/Support')}>Support</li>
                    <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                    <li onClick={()=>navigate('/TermsAndConditions')}>Terms and Conditions</li>
                </ul>
            </div>
            </div>
            <div className="disclaimer">© AI2Claim 2024</div>
    </div>
  );
};

export default PrivacyPolicy;
