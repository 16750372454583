import React, { useEffect, useState } from "react";
import '../Assets/LandingPage.css'
import Player from '../Materials/default.mp4'
import { Navigate, useNavigate } from "react-router-dom";
import AI2Logo from '../Materials/AI2Logow.png'
import AI2LogoB from '../Materials/AI2Logoq.png'
import Room from '../Materials/room1.png'
import Room1 from '../Materials/room2.png'
import Room2 from '../Materials/room3.png'
import RoundRoom from '../Materials/round-room1.png'
import RoundRoom1 from '../Materials/round-room2.png'
import RoundRoom2 from '../Materials/round-room3.png'
import HomePool from '../Materials/home_pool.png'
import HomePool1 from '../Materials/home_pool_front.png'
import Rooftop1 from '../Materials/rooftop-1.png'
import Rooftop2 from '../Materials/rooftop-2.png'
import Rooftop3 from '../Materials/rooftop-3.png'
import Rooftop4 from '../Materials/Rooftop-4.png'
import Rooftop5 from '../Materials/Rooftop-5.png'
import Rooftop6 from '../Materials/Rooftop-6.png'
import Rooftop7 from '../Materials/Rooftop-7.png'
import Rooftop8 from '../Materials/Rooftop-8.png'
import Rooftop9 from '../Materials/Rooftop-9.png'
import Rooftop10 from '../Materials/Rooftop-10.png'




const LandingPage = () => {
    const [highlight,setHighlight] = useState('fencing?')
    const [navBg, setNavBg] = useState(false);
    const navigate = useNavigate()
    useEffect(()=>{
     changeText();
    },[])
    
    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const changeText = () => {
        let x = ['gutters?', 'windows?', 'roof?','fencing?'];
        let index = 0;
    
        setInterval(() => {
            setHighlight(x[index]);
            index = (index + 1) % x.length;
        }, 4000);
    };
    useEffect(() => {
        window.addEventListener('scroll', changeNavColor);
        return () => {
          window.removeEventListener('scroll', changeNavColor);
        }
      }, [])
    const changeNavColor = () =>
        {
        }
      
    return (
        <div>
            <div className="bg-image">
            <div className={navBg ? "header-scroll":"header"}>
            <div className="logo" onClick={()=>navigate('/')}>{navBg ? <img style={{height:'9Vh'}} src={AI2LogoB} alt='AI2'/>:<img src={AI2LogoB} alt='AI2'/>}</div>
            <nav>
                <ul>
                <li onClick={()=>navigate('/how-it-works')}>How it Works</li>
                <li onClick={()=>navigate('/pricing')}>Pricing</li>
                <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                </ul>
            </nav>
            <button className="login-btn">Login</button>
            </div> <br/>
                <br/>
                <br/>
                <br/>
               <br/>
               <br/>
            <div className="banner">
              


            <h1>Build with ease. Strong <br/> structures, simple process.</h1>
            </div>
            <br/>
            <br/>
           

            <div className="search-bar">
            <i class="bi bi-search"></i><input type="text" placeholder="Find your home, start your project..." />
            </div>
            </div>
            <br/>
                <br/>
                <br/>
            <div className="">
               

            <section className="our-story-section">
      <div className="our-story-content">
        <h2 className="our-story-title">  Our Story</h2>
        <br/>
        <p className="our-story-text">
          With a proven track record of success, Ai2Claim LLC founded its operations in Nebraska, USA. With the help of the amazing founders and a team of experienced IT experts, they adapted the platform to meet the specific needs and regulations of the U.S. insurance industry. Through strategic partnerships with leading insurance providers and home repair contractors, Ai2Claim quickly established a strong presence locally. The company’s innovative approach and commitment to customer satisfaction resonated with American consumers, making Ai2Claim a trusted and reliable solution for insurance claims and home repair services across the midwest.
        </p>
      </div>
      <div className="our-story-images">
        <img src={Rooftop1} alt="Roof Repair" className="our-story-image" />
        <img src={Rooftop2} alt="Worker with Tools" className="our-story-image" />
        <img src={Rooftop3} alt="Completed Roof" className="our-story-image" />
      </div>
    </section>
            
            </div>
            
            <div className="custom-plan-1">
                
            <div className="custom-plan-text-1">
            <h1>
           Your Roofing <br/><span style={{color:"#1179EC"}}>Project</span > <span style={{color:"#1179EC"}}>,Your</span> Way.<br/>Customized plans <br/>for specific needs.
            </h1>
            </div>
            <div className="custom-plan-image-container-1">
            <img className="custom-plan-img-1" src={Rooftop4}/>
            <img className="custom-plan-img-1" src={Rooftop5}/>

            </div>
            </div>
           

            <div className="custom-plan">
                
            <div className="custom-plan-text">
            <h1>
            <span style={{color:"#1179EC"}}> AI2claim:</span> Your No.1 Roofing <br/>Solutions.
            </h1>
            </div>
            <div className="custom-plan-image-container">
            <img className="custom-plan-img" src={Rooftop6}/>
            <img className="custom-plan-img" src={Rooftop9}/>
            <img className="custom-plan-img" src={Rooftop8}/>
            <img className="custom-plan-img" src={Rooftop7}/>

            <img className="custom-plan-img" src={Rooftop10}/>

          

            </div>
            <p>At Ai2Claim, we understand that a damaged roof can be a major inconvenience and safety hazard. That's why we're dedicated to providing comprehensive roofing solutions tailored to your specific needs. From storm damage repairs to complete roof replacements, our team of experienced professionals is here to assist you every step of the way. We work closely with trusted insurance providers and roofing contractors to ensure a smooth and hassle-free process. With Ai2Claim, you can have peace of mind knowing that your roofing needs are in good hands.</p>
            </div>
            <div className="payment-section">
            <div className="payment-section-features">
            <div className="payment-section-features-item">
            </div>
            <div className="payment-section-features-item">
            <h2>A custom <span style={{color:"#1179EC",fontWeight:'bold'}}>plan for<br/> your</span> next project</h2>
            <p>Specific needs require a specific plan. Build the outcome you want. You choose your project type, order materials, select an install crew and manage payment on your terms.</p>
            </div>
            
            </div>
            </div>
            <br/>
            <br/>

            <br/>
            <br/>
            <div className="project-sections-container">
       
    <div className="project-sections">
  
        <h1>What makes <br/>AI2Claim different <br/> from other roofing <br/> companies?<br/></h1>
    </div>
    <div className="project-list">
        <p>At AI2Claim, we're dedicated to providing comprehensive roofing solutions tailored to your specific needs. Our team of experienced professionals is here to assist you throughout the entire process, from initial consultation to final installation. We offer a wide range of services, including:</p>
        <ul>
            <li>Roof inspections and assessments</li>
            <li>Insurance claim assistance</li>
            <li>Roof repairs and replacements</li>
            <li>Roofing maintenance and cleaning</li>
        </ul>
        <p>With our in-depth knowledge of the local market and commitment to exceptional customer service, we strive to make your roofing experience as stress-free as possible.</p>
    </div>
</div>

            <div className="Looking-for-con">
            <div className="Looking-for">
                <br/>                <br/>

                <h1 style={{ fontSize: '2.6rem', fontWeight: '700' }}>
    Speak with a <br />
    <span style={{ color: "#1179EC", fontWeight: '700' }}>SPECIALIST?</span>
</h1>
           

            <button className="login-btn-1">Contact Us</button>
            </div>
            <div className="footer">
            <div className="footer-list">
                <ul>
                    <li>Resources</li>
                    <li>Blog</li>
                    <li>Make a Payment</li>
                </ul>
            </div>
            <div className="footer-logo"><img src={AI2Logo}/></div>
            <div className="footer-list">
            <ul>
                    <li onClick={()=>navigate('/Support')}>Support</li>
                    <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                    <li onClick={()=>navigate('/TermsAndConditions')}>Terms and Conditions</li>
                </ul>
            </div>
            </div>
            </div>
            <div className="disclaimer">© AI2Claim. 2024</div>
        </div>
    )
}

export default LandingPage;