import '../Assets/Support.css';
import React, { useEffect, useState } from "react";
import AI2Logo from '../Materials/AI2Logow.png'
import { useNavigate } from "react-router-dom";
import AI2LogoB from '../Materials/AI2Logoq.png'

const Support = () => {
    const [highlight,setHighlight] = useState('fencing?')
    const [navBg, setNavBg] = useState(false);
    const navigate = useNavigate()
    useEffect(()=>{
     changeText();
    },[])
    
    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const changeText = () => {
        let x = ['gutters?', 'windows?', 'roof?','fencing?'];
        let index = 0;
    
        setInterval(() => {
            setHighlight(x[index]);
            index = (index + 1) % x.length;
        }, 4000);
    };
    useEffect(() => {
        window.addEventListener('scroll', changeNavColor);
        return () => {
          window.removeEventListener('scroll', changeNavColor);
        }
      }, [])
    const changeNavColor = () =>
        {
        }

    return (
        <div className='support-body'>
           <div className="pricing">
     <div className={navBg ? "header-scroll":"header"}>
            <div className="logo" onClick={()=>navigate('/')}>{navBg ? <img style={{height:'9Vh'}} src={AI2LogoB} alt='AI2'/>:<img src={AI2LogoB} alt='AI2'/>}</div>
            <nav>
                <ul>
                <li onClick={()=>navigate('/how-it-works')}>How it Works</li>
                <li onClick={()=>navigate('/pricing')}>Pricing</li>
                <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                </ul>
            </nav>
            <button className="login-btn">Login</button>
            </div> 
           
            </div>
            <br/>
            <br/>
            <br/>
            <br/>


            <div className="support-container">
                <h1>General Support Information</h1>
                <p>Welcome to Load 41 Support! We're here to help you with any questions or issues you might have.</p>

                <h2>Contact Us</h2>
                <p>If you need immediate assistance, you can contact our support team through:</p>
                <ul className="contact-list">
                    <li><strong>Name:</strong>  Ai2Claim</li>
                    <li><strong>Address:</strong> Ai2Claim, 1006 West Centennial Rd, Papillion, NE 68046, USA </li>
                    <li><strong>Phone:</strong> +1-402-714-5726</li>
                    <li><strong>Email:</strong> <a href="mailto:support@ai2claim.com ">support@ai2claim.com </a></li>
                    <li><strong>Contact Form:</strong> Located at the bottom of the page</li>
                </ul>
                <p>Our support hours are Monday to Friday, 9 AM to 5 PM (CST).</p>

                <h2>Frequently Asked Questions (FAQs)</h2>
                <div className="faq">
                    <h3>Q: How do I delete my account?</h3>
                    <p>
                        A: To delete your account, please go to "Account Options" and then click "Edit Details." On the top right-hand of the screen, click the trash icon. A confirmation window will open; then select "Delete."
                    </p>

                    <ol>
                        <li><strong>Open the App</strong><br />
                            Launch the app on your device. You should see the home screen.
                        </li>
                        <li><strong>Navigate to Settings</strong><br />
                            Tap on the settings icon (usually represented by a gear or three vertical dots) located on the main screen.
                        </li>
                        <li><strong>Go to Account Settings</strong><br />
                            In the settings menu, find and select "Account Settings".
                        </li>
                        <li><strong>Edit Account</strong><br />
                            Within the Account Settings, look for the "Edit Account" option and tap on it.
                        </li>
                        <li><strong>Delete Account</strong><br />
                            Scroll to the bottom of the Edit Account page to find the "Delete Account" option. Tap on "Delete Account".
                        </li>
                        <li><strong>Confirm Account Deletion</strong><br />
                            A confirmation prompt will appear, asking if you are sure you want to delete your account. Confirm that you want to delete your account. This action is usually irreversible.
                        </li>
                        <li><strong>Return to Home Page</strong><br />
                            After confirming the account deletion, you will be automatically redirected to the home page of the app.
                        </li>
                    </ol>

                    <h3>Q: How do I reset my password?</h3>
                    <p>
                        A: To reset your password, click on the "Forgot Password" link on the login page. Enter your email address, and we'll send you instructions on how to reset your password.
                    </p>
                </div>

      <h2>Technical Support</h2>
      <p>If you are experiencing technical issues with our website or mobile app, please try the following troubleshooting steps:</p>
      <ul>
        <li>Clear your browser's cache and cookies.</li>
        <li>Ensure you are using the latest version of your browser or app.</li>
        <li>Restart your device.</li>
      </ul>
      <p>If the issue continues, please provide a detailed description through the Contact Form at the bottom of the page, or call us at +1-402-714-5726. Our technical support team will respond shortly.</p>
    </div>
    <div className="footer">
            <div className="footer-list">
                <ul>
                    <li>Resources</li>
                    <li>Blog</li>
                    <li>Make a Payment</li>
                </ul>
            </div>
            <div className="footer-logo"><img src={AI2Logo}/></div>
            <div className="footer-list">
            <ul>
                    <li onClick={()=>navigate('/Support')}>Support</li>
                    <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                    <li onClick={()=>navigate('/terms-and-conditions')}>Terms and Conditions</li>
                </ul>
            </div>
            </div>
            <div className="disclaimer">© AI2Claim 2024</div>
        </div>
    );
};

export default Support;
