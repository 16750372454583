import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "../LandingPage/LandingPage";
import NotFound from "../LandingPage/NotFound";
import '../Assets/LandingPage.css';
import { Container } from "react-bootstrap";
import HowItWorks from "../LandingPage/Howitworks";
import PricingPage from "../LandingPage/PricingPage";
import ContactUs from "../LandingPage/Contact-Us";
import WaitlistForm from "../LandingPage/Waitlist";
import TermsAndConditions from "../Components/TermsAndConditions";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import Support from "../Components/Support";




const Main = () => {
  const uid = sessionStorage.getItem('id')
  const PrivateRoute = ({ children }) => {
    return uid ? children : <Navigate to="/" />
  };
  const PublicRoute = ({ children }) => {
    return !uid ? children : <Navigate to="/dashboard" />
  };
  const NotFoundChild = () => {
    return <div>
      <Container style={{ color: '#000', margin: '8% auto', height: '54vh', paddingTop: '10%', background: 'transparent' }}>
        <h1 style={{ color: '#000' }}>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for could not be found.</p>
      </Container>
    </div>;
  };
  return (
    <>
      <Routes>
        <Route exact path="*" element={<NotFound />} />
        <Route exact path='/' element={<LandingPage />} />
        <Route exact path='/how-it-works' element={<HowItWorks />} />
        <Route exact path='/pricing' element={<PricingPage />} />
        <Route exact path='/TermsAndConditions' element={<TermsAndConditions />} />
        <Route exact path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        <Route exact path='/Support' element={<Support />} />
        <Route exact path='/contact-us' element={<ContactUs />} />
        <Route exact path='/waitlist' element={<WaitlistForm />} />
      </Routes>
    </>
  )
}

export default Main;
