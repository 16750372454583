import '../Assets/TermsAndConditions.css';
import React, { useEffect, useState } from "react";
import AI2Logo from '../Materials/AI2Logow.png'
import { useNavigate } from "react-router-dom";
import AI2LogoB from '../Materials/AI2Logoq.png'

const TermsAndConditions = () => {
    const [highlight,setHighlight] = useState('fencing?')
    const [navBg, setNavBg] = useState(false);
    const navigate = useNavigate()
    useEffect(()=>{
     changeText();
    },[])
    
    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const changeText = () => {
        let x = ['gutters?', 'windows?', 'roof?','fencing?'];
        let index = 0;
    
        setInterval(() => {
            setHighlight(x[index]);
            index = (index + 1) % x.length;
        }, 4000);
    };
    useEffect(() => {
        window.addEventListener('scroll', changeNavColor);
        return () => {
          window.removeEventListener('scroll', changeNavColor);
        }
      }, [])
    const changeNavColor = () =>
        {
        }

  return (<div className="term-body">
     <div className="pricing">
     <div className={navBg ? "header-scroll":"header"}>
            <div className="logo" onClick={()=>navigate('/')}>{navBg ? <img style={{height:'9Vh'}} src={AI2LogoB} alt='AI2'/>:<img src={AI2LogoB} alt='AI2'/>}</div>
            <nav>
                <ul>
                <li onClick={()=>navigate('/how-it-works')}>How it Works</li>
                <li onClick={()=>navigate('/pricing')}>Pricing</li>
                <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                </ul>
            </nav>
            <button className="login-btn">Login</button>
            </div> 
           
            </div>
            <br/>
            <br/>
            <br/>
            <br/>

    <div className="terms-container">
      <h1 className="terms-title">AI2Claim Terms and Conditions</h1>
      <p className="terms-date">Last Modified: Aug 23, 2024</p>
      <p className="terms-intro">
        The following terms and conditions (this "Agreement") constitute a binding contract between
        you ("Customer," "you," or "your") and AI2Claim LLC ("Provider," "we," or "us"). This
        Agreement governs your access to and use of the Cloud Services.
      </p>
      <p className="terms-effective-date">
       <strong> EFFECTIVE DATE:</strong> This Agreement takes effect when you click the "I ACCEPT" button
        below or by accessing or using the Cloud Services (the "Effective Date"). By doing so, you (A)
        acknowledge that you have read and understand this Agreement; (B) represent and warrant that
        you have the right, power, and authority to enter into this Agreement, and if entering into this
        Agreement on behalf of an organization, that you have the legal authority to bind that
        organization; and (C) accept this Agreement and agree to be legally bound by its terms.
      </p>
      <p className="terms-decline">
        If you do not agree to these terms, please select the "I DECLINE" button below. If you do not
        accept these terms, you may not access or use the Cloud Services.
      </p>

      <h2>1. Definitions</h2>
      <p className="terms-content">
        (a) <strong style={{  color: 'black' }}>"Authorized User"</strong> means the Customer and the Customer's employees, consultants,
        contractors, and agents who (i) are authorized by the Customer to access and use the Cloud
        Services under the rights granted to the Customer pursuant to this Agreement and (ii) for whom
        access to the Cloud Services has been purchased hereunder.
      </p>
      <p className="terms-content">
        (b) <strong style={{  color: 'black' }}>"Cloud Services"</strong> refers to the services provided by the Provider under this Agreement, as
        detailed on the Provider's website available at AI2Claim.com.
      </p>
      <p className="terms-content">
      (c) <strong style={{  color: 'black' }}> "Customer Data"</strong> means any information, data, and other content, other than Aggregated 
Statistics, in any form or medium, that is submitted, posted, or otherwise transmitted by or on 
behalf of the Customer or any other Authorized User through the Cloud Services.
      </p>
      <p className="terms-content">
        (d)  <strong style={{  color: 'black' }}>  "Documentation" </strong> refers to any user manuals, handbooks, guides, or online help related to 
the Cloud Services provided by the Provider to the Customer, either electronically or in hard 
copy, available at AI2Claim.com.
      </p>
      <p className="terms-content">
        (e)<strong style={{  color: 'black' }}> "Provider IP" </strong> refers to the Cloud Services, Documentation, and all intellectual property 
provided to the Customer or any other Authorized User in connection with the foregoing. For the 
avoidance of doubt, Provider IP includes Aggregated Statistics and any information, data, or 
other content derived from the Provider's monitoring of the Customer's access to or use of the 
Cloud Services but does not include Customer Data. 
      </p>
      <p className="terms-content">
        (f) <strong style={{  color: 'black' }}>"Third-Party Products" </strong> means any products, content, services, information, websites, or 
other materials owned by third parties and incorporated into or accessible through the Cloud 
Services.
      </p>      
      
      <h2>2. Access and Use</h2>
      <p className="terms-content">
        (a)  <strong style={{  color: 'black' }}>Provision of Access. </strong> Subject to and conditioned on your payment of Fees and compliance 
with all terms and conditions of this Agreement, the Provider grants you a revocable, non
exclusive, non-transferable, non-sublicensable, limited right to access and use the Cloud Services 
during the Term solely for your internal business operations by Authorized Users in accordance 
with the terms and conditions herein. The Provider will provide you with the necessary 
passwords and access credentials to access the Cloud Services.
      </p>
      <p className="terms-content">
        (b) <strong style={{  color: 'black' }}>Documentation License. </strong> Subject to the terms and conditions of this Agreement, the Provider 
grants you a non-exclusive, non-sublicensable, non-transferable license for Authorized Users to 
use the Documentation during the Term solely for your internal business purposes in connection 
with the use of the Cloud Services.
      </p>
      <p className="terms-content">
        (c)  <strong style={{  color: 'black' }}>Use Restrictions. </strong> You shall not, and shall not permit any Authorized Users to, use the Cloud 
Services, any software component of the Cloud Services, or Documentation for any purposes 
beyond the scope of the access granted in this Agreement. Specifically, you shall not (i) copy, 
modify, or create derivative works of the Cloud Services or Documentation; (ii) rent, lease, lend, 
sell, license, sublicense, assign, distribute, publish, transfer, or otherwise make available the 
Cloud Services or Documentation except as expressly permitted; (iii) reverse engineer, 
disassemble, decompile, decode, or otherwise attempt to derive or gain access to any software 
component of the Cloud Services; (iv) remove any proprietary notices from the Cloud Services 
or Documentation; or (v) use the Cloud Services or Documentation in any manner that infringes 
or violates any intellectual property rights, applicable law, or regulation.
      </p>
      <p className="terms-content">
        (d)  <strong style={{  color: 'black' }}>Aggregated Statistics. </strong>Notwithstanding anything to the contrary in this Agreement, the 
Provider may monitor the Customer's use of the Cloud Services and collect data and information 
related to such use, to be used by the Provider in an aggregated and anonymized manner 
("Aggregated Statistics"). As between the Provider and the Customer, all right, title, and interest 
in Aggregated Statistics belong to and are retained solely by the Provider. You acknowledge that 
the Provider may compile Aggregated Statistics based on Customer Data input into the Cloud 
Services, which may be used publicly, provided they do not identify the Customer or the 
Customer's Confidential Information.
      </p>
      <p className="terms-content">
        (e) <strong style={{  color: 'black' }}>Reservation of Rights. </strong> The Provider reserves all rights not expressly granted to the 
Customer in this Agreement. No rights or licenses are granted by implication, waiver, estoppel, 
or otherwise.
      </p>
      <p className="terms-content">
        (f) <strong style={{  color: 'black' }}>Suspension. </strong>The Provider may temporarily suspend the Customer's and any other Authorized 
User's access to the Cloud Services under certain circumstances, including security threats, legal 
obligations, or disruptions caused by the Customer's use. The Provider will make commercially 
reasonable efforts to provide notice of any suspension and resume access as soon as possible. 
The Provider will not be liable for any damages resulting from such suspensions. 
      </p>
<h2>3. Customer Responsibilities 
</h2>
      <p className="terms-content">
      (a) <strong style={{  color: 'black' }}>Acceptable Use Policy. </strong> The Cloud Services may not be used for unlawful, fraudulent, 
offensive, or obscene activities, as further described in the Provider's acceptable use policy 
("AUP") located at AI2Claim.com/acceptable-use-policy. You will comply with all terms of this 
Agreement, applicable laws, and guidelines posted on the Provider's website.
      </p><p className="terms-content">
      (b) <strong style={{  color: 'black' }}>Account Use. </strong> You are responsible for all uses of the Cloud Services and Documentation 
resulting from access provided by you, directly or indirectly, and for all acts and omissions of 
Authorized Users. Any act by an Authorized User that would constitute a breach of this 
Agreement will be deemed a breach by you.
      </p><p className="terms-content">
      (c) <strong style={{  color: 'black' }}>Customer Data. </strong> You grant the Provider a non-exclusive, royalty-free, worldwide license to 
use Customer Data as necessary for providing the Cloud Services. You are responsible for 
ensuring that Customer Data and its use comply with this Agreement and applicable laws.
      </p><p className="terms-content">
      (d) <strong style={{  color: 'black' }}>Passwords and Access Credentials. </strong> You are responsible for keeping your passwords and 
      access credentials confidential and will notify the Provider of any unauthorized access.
      </p><p className="terms-content">
      (e) <strong style={{  color: 'black' }}>Third-Party Products. </strong> The Services may permit access to Third-Party Products, subject to 
their own terms and conditions. If you do not agree to these terms, you should not use such 
Third-Party Products.
      </p>
      <h2>4. Fees and Payment
</h2>
      <p className="terms-content">
       (a) <strong style={{  color: 'black' }}>Fees. </strong> The Customer shall pay the Provider the fees as described on AI2Claim.com/pricing 
("Fees") within thirty (30) days from the invoice date. If payment is not made when due, the 
Provider may charge interest, recover costs of collection, or suspend access to the Cloud 
Services. All fees are exclusive of taxes, and the Customer is responsible for all applicable taxes.
      </p><p className="terms-content">
      (b) <strong style={{  color: 'black' }}>Third-Party Products Escrow.</strong>  Payments for certain Third-Party Products may be held in 
escrow by the Provider until conditions for their release are met. The Provider will manage these 
funds according to the terms specified in the Customer's order and this Agreement.
      </p><p className="terms-content">
      (c) <strong style={{  color: 'black' }}>Release of Escrow Funds. </strong>  The Escrow Funds will be released according to specific 
conditions, including the delivery and acceptance of Third-Party Products or adjustments in 
scope or payment. 

      </p><p className="terms-content">
      (d) <strong style={{  color: 'black' }}>Taxes. </strong> The applicable Third-Party shall be treated as the owner of the Escrow Funds for tax 
purposes and shall report and pay any taxes attributable to the Escrow Funds.

      </p><h2>5. Confidential Information 
</h2>
      <p className="terms-content">
      During the Term, both parties may disclose confidential information ("Confidential 
Information"). Confidential Information excludes information that is public, known to the 
receiving party, lawfully obtained from a third party, or independently developed. The receiving 
party agrees to protect the disclosing party's Confidential Information and not disclose it except 
as necessary to comply with legal obligations or to establish rights under this Agreement.
      </p><h2>6. Privacy Policy
</h2>
      <p className="terms-content">
      The Provider complies with its privacy policy, available at AI2Claim.com/privacy-policy 
("Privacy Policy"). By accessing, using, and providing information through the Cloud Services, 
you acknowledge and accept the Privacy Policy and consent to the Provider's actions in 
compliance with it.</p>
<h2>7. Intellectual Property Ownership; Feedback
</h2>      <p className="terms-content">
Between you and us, (a) we retain all rights, titles, and interests, including all intellectual 
property rights, in and to the Cloud Services, and (b) you retain all rights, titles, and interests, 
including all intellectual property rights, in and to the Customer Data. If you, your employees, 
contractors, or agents provide us with any communications or materials, whether by mail, email, 
telephone, or other means, that suggest or recommend changes to the Cloud Services—including, 
but not limited to, new features or functionalities, or any comments, questions, suggestions, or 
similar input ("Feedback")—we are free to use such Feedback without any obligation or 
limitation that might otherwise apply between you and us regarding that Feedback. All Feedback 
is considered non-confidential. You hereby assign to us, and will ensure that your employees, 
contractors, and agents also assign to us, all rights, titles, and interests in any ideas, know-how, 
concepts, techniques, or other intellectual property rights contained in the Feedback. We are free 
to use these for any purpose, without any requirement for attribution or compensation to you or 
any third party, although we are not obligated to utilize any Feedback provided.
      </p><h2>8. Limited Warranty and Warranty Disclaimer</h2>
      <p className="terms-content">
        <strong>(a) Provider Warranty</strong><br/>
        The Provider warrants that it delivers the Cloud Services with a commercially reasonable level of care and expertise. However, this warranty does not extend to any Third-Party Products, and the Provider expressly disclaims all warranties related to them.
      </p>
      <p className="terms-content">
        <strong>(b) Customer Warranty</strong><br/>
        You warrant that you hold all rights, titles, and interests, including intellectual property rights, in and to the Customer Data. Additionally, you warrant that both the Customer Data and your use of the Cloud Services comply with the Acceptable Use Policy (AUP).
      </p>
      <p className="terms-content">
        <strong>(c) Disclaimer of Warranties</strong><br/>



        Except for the limited warranty provided in Section 9(a), the Cloud Services are offered "as is." The Provider expressly disclaims all 
        warranties, whether express, implied, statutory, or otherwise. This includes, but is not limited to, implied warranties of merchantability, 
        fitness for a particular purpose, title, and non-infringement, as well as any warranties arising from course of dealing, usage, or trade
         practice. The Provider makes no guarantee that the Cloud Services, or any products or outcomes derived from their use, will meet your
          or any other party's requirements, operate without interruption, achieve any intended results, be compatible or work with your or any third party's software, systems, or other services, or be secure, accurate, complete, free of harmful code, or error-free. Additionally, there is no guarantee that any errors or defects can or will be corrected.
      </p>


      <h2>9. Indemnification</h2>
      <p className="terms-content">
        <strong>(a) Provider Indemnification</strong><br/>
        (i) The Provider agrees to indemnify, defend, and hold the Customer harmless from any and all losses, damages, liabilities, 
        deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of any kind, 
        including attorneys' fees ("Losses"), that the Customer incurs due to any third-party 
        claim, suit, action, or proceeding ("Third-Party Claim") alleging that the Cloud Services,
        or the use of the Cloud Services as permitted under this Agreement, infringe or misappropriate that third party's U.S. 
        intellectual property rights. This indemnification is contingent upon the Customer promptly notifying the Provider in 
        writing of the Third-Party Claim, cooperating fully with the Provider, and allowing the Provider to have sole control 
        over the defense and settlement of the Third-Party Claim.
      </p>
      <p className="terms-content">
        (ii) If a Third-Party Claim is made, or if the Provider anticipates that such a claim will be made, the Customer agrees to allow 
        the Provider, at its sole discretion, to either (A) modify or replace the Cloud Services, or any component thereof, to make it 
        non-infringing, or (B) obtain the necessary rights for the Customer to continue using the Cloud Services. If the Provider determines
         that neither option is reasonably feasible, the Provider may terminate this Agreement, either in whole or in part, effective 
         immediately upon providing written notice to the Customer. This section outlines your sole remedies and the Provider's sole 
         liability and obligation for any actual, threatened, or alleged Third-Party Claims that the Cloud Services infringe, misappropriate, 
         or otherwise violate any third party's intellectual property rights.
      </p>
      <p className="terms-content">
        (iii) The indemnification obligations in this section do not apply to the extent that any Third-Party Claim arises from Customer 
        Data or Third-Party Products.
      </p>
      <p className="terms-content">
        <strong>(b) Customer Indemnification</strong><br/>
        The Customer agrees to indemnify, hold harmless, and, at the Provider's option, defend the Provider and its officers, 
        directors, employees, agents, affiliates, successors, and assigns from any and all Losses arising from or related to 
        any Third-Party Claim that (i) alleges that the Customer Data, or the use of such data in accordance with this Agreement, 
        infringes or misappropriates the U.S. intellectual property rights of that third party; or (ii) is based on the Customer's 
        or any Authorized User's negligence, willful misconduct, or unauthorized use of the Cloud Services. The Customer may not settle 
        any Third-Party Claim against the Provider without the Provider's consent. Additionally, the Provider reserves the right, at its 
        discretion, to defend itself against any such Third-Party Claim or to participate in the defense using counsel of its choice.
      </p>
      
      <h2>10. Limitations of Liability</h2>
      <p className="terms-content">
        UNDER NO CIRCUMSTANCES SHALL THE PROVIDER BE LIABLE FOR ANY DAMAGES OR LOSSES ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, REGARDLESS OF THE LEGAL OR EQUITABLE BASIS, INCLUDING BUT NOT LIMITED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE. THIS INCLUDES, BUT IS NOT LIMITED TO: 
        <span className="terms-content">(a) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES;</span> 
        <span className="terms-content">(b) INCREASED COSTS, LOSS OF VALUE, OR LOST BUSINESS, PRODUCTION, REVENUE, OR PROFITS;</span> 
        <span className="terms-content">(c) LOSS OF GOODWILL OR REPUTATION;</span> 
        <span className="terms-content">(d) INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR RECOVERY OF DATA, OR BREACH OF DATA OR SYSTEM SECURITY;</span> 
        OR 
        <span className="terms-content">(e) COST OF REPLACEMENT GOODS OR SERVICES.</span> THIS APPLIES EVEN IF THE PROVIDER WAS INFORMED OF THE POSSIBILITY OF SUCH DAMAGES OR IF SUCH DAMAGES WERE OTHERWISE FORESEEABLE.
      </p>
      <p className="terms-content">
        IN ANY CASE, THE PROVIDER'S TOTAL LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, SHALL NOT EXCEED THE TOTAL AMOUNT PAID TO THE PROVIDER UNDER THIS AGREEMENT IN THE TWELVE (12) MONTHS PRIOR TO THE EVENT GIVING RISE TO THE CLAIM, OR $100.00, WHICHEVER IS LESS.
      </p>
      <p className="terms-content">
        The exclusions and limitations stated in this Section do not apply to the parties' obligations under Section 9.
      </p><h2>11. Term and Termination 

</h2>
      <p className="terms-content">
      (a)  <strong> Term.</strong> This Agreement begins on the Effective Date and remains in effect until it is 
terminated. </p><p className="terms-content">
      (b)  <strong> Termination.</strong> In addition to other specific termination rights outlined in this Agreement: </p>
      
      <p className="terms-content">
      (i) The Provider may terminate this Agreement for any reason with thirty (30) days' advance 
      notice. You may terminate this Agreement for any reason with sixty (60) days' advance notice. </p>
      
      <p className="terms-content">
      (ii) Either party may terminate this Agreement by providing written notice to the other if the 
other party materially breaches the Agreement and such breach either (A) cannot be remedied, or 
(B) can be remedied but remains unaddressed 30 days after the non-breaching party provides 
notice of the breach.  </p>

<p className="terms-content">
(iii) Either party may immediately terminate this Agreement by giving written notice to the other 
if the other party (A) becomes insolvent, is unable to pay its debts as they become due, or fails to 
pay its debts; (B) files for or is subject to bankruptcy or insolvency proceedings; (C) makes or 
attempts to make a general assignment for the benefit of its creditors; or (D) has a receiver, 
trustee, custodian, or similar agent appointed to manage or sell any significant portion of its 
property or business. </p>
<p className="terms-content">
(c)  <strong> Effect of Termination.</strong> Upon termination of this Agreement, you must immediately stop 
using the Provider IP. Termination or expiration of this Agreement does not affect your 
obligation to pay any Fees that have become due prior to termination, nor does it entitle you to 
any refund. </p>
<p className="terms-content">
(d)  <strong> Survival.</strong> The provisions of this Section 11(d), along with Sections 4, 5, 9, 10, 13, 14, 15, 
and 16, and any other rights, obligations, or requirements intended to survive the termination of 
this Agreement, will remain in effect even after the Agreement is terminated.  </p>

<h2>12. Modifications

</h2>
      <p className="terms-content">
      You acknowledge and agree that we reserve the right to modify this Agreement at our sole 
discretion. Any changes will become effective upon posting. We will notify you of these 
modifications via direct email communication. It is your responsibility to review and understand 
any changes. Your continued use of the Cloud Services after the modifications take effect will be 
considered as your acceptance of the updated terms. We will make reasonable efforts to provide 
at least 30 days' advance notice of any changes to service levels that we reasonably anticipate 
may lead to a significant reduction in quality or services. </p>

<h2>13. Dispute Resolution

</h2>
      <p className="terms-content">
      (a)  <strong> Negotiation.</strong> In the event of any dispute, controversy, or claim arising out of or relating to 
this Agreement, including any breach, termination, or validity thereof (collectively, "Dispute"), 
the parties shall first make a sincere effort to resolve the Dispute through good faith negotiation 
between their respective representatives. </p>
<p className="terms-content">
(b)  <strong> Mediation.</strong> If the parties are unable to reach a resolution through negotiation within thirty 
(30) days after one party provides written notice of the Dispute to the other, either party may 
initiate mediation. The mediation shall be conducted in accordance with the rules and procedures 
of a mutually agreed-upon mediation provider. The costs of mediation will be shared equally by 
the parties unless otherwise agreed. </p>
<p className="terms-content">
(c)  <strong> Arbitration.</strong> Should the parties fail to resolve the Dispute through negotiation or mediation, 
the Dispute shall be finally resolved through binding arbitration. The arbitration shall be 
conducted in accordance with the rules and procedures of a mutually agreed-upon arbitration 
provider. The arbitration shall take place in the city of Lincoln, Nebraska, and the arbitrator's 
decision will be final and binding on both parties. The costs of arbitration shall be shared equally 
by the parties unless otherwise agreed. </p>
<p className="terms-content">
(d)  <strong> Equitable Relief.</strong> Notwithstanding the above, either party may seek injunctive or other 
equitable relief in a court of competent jurisdiction to prevent or stop any actual or threatened 
breach of this Agreement. </p>
<p className="terms-content">
(e)  <strong> Waiver of Jury Trial.</strong> To the fullest extent permitted by applicable law, the parties waive the 
right to a trial by jury in any legal suit, action, or proceeding arising out of or related to this 
Agreement or the rights granted herein. </p>
<p className="terms-content">
(f)  <strong> Class Action Waiver.</strong> To the fullest extent permitted by applicable law, any arbitration, legal 
suit, action, or proceeding arising out of or related to this Agreement or the rights granted herein 
shall be conducted solely on an individual basis and not as a class action or other representative 
action. The parties expressly waive any right to bring or participate in a class action or other 
representative action.</p>
<p className="terms-content">
(g)  <strong> Limitation Period.</strong> Any claim or cause of action arising out of or related to this Agreement 
or the rights granted herein must be filed within one (1) year after such claim or cause of action 
arose, or it shall be forever barred, notwithstanding any statute of limitations or other law to the 
contrary. </p>

<h2>14. Export Regulation </h2>
      <p className="terms-content">
      The Cloud Services incorporate software and technology that may be governed by U.S. export 
control laws, including the U.S. Export Administration Act and its related regulations. You are 
prohibited from directly or indirectly exporting, re-exporting, or distributing the Cloud Services 
or the software and technology within them to, or making them accessible from, any country or 
jurisdiction where such actions are restricted or prohibited by law. You must adhere to all 
applicable federal laws, regulations, and rules and fulfill all necessary requirements (including 
obtaining any required export licenses or other governmental approvals) before exporting, re
exporting, releasing, or making the Cloud Services or their included software and technology 
available outside the United States.  </p>

<h2>15. Governing Law and Jurisdiction

</h2>
      <p className="terms-content">
      This agreement is governed by and construed in accordance with the internal laws of the State of 
Nebraska without giving effect to any choice or conflict of law provision or rule that would 
require or permit the application of the laws of any jurisdiction other than those of the State of 
Nebraska. Any legal suit, action, or proceeding arising out of or related to this agreement or the 
rights granted hereunder will be instituted exclusively in the federal courts of the United States 
or the courts of the State of Nebraska in each case located in the cities of Omaha and Lincoln, 
and each party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, 
action, or proceeding. </p>

<h2>16. Miscellaneous

</h2>
      <p className="terms-content">
      This Agreement represents the complete and exclusive understanding between the parties 
regarding its subject matter, superseding all prior or simultaneous discussions, agreements, 
representations, and warranties, whether written or oral, related to that subject matter. Any 
notices to us must be sent to our corporate headquarters, either in person, by certified or 
registered mail with return receipt requested and postage prepaid, or via a recognized overnight 
courier service. Notices will be considered received once they are delivered to us. 
 </p>
 <p className="terms-content">
 By agreeing to this, you also consent to receive electronic communications from us, which may 
include information about applicable fees, charges, transactional details, and other updates 
related to the Cloud Services. You agree that any notices, agreements, disclosures, or other 
communications we send to you electronically will fulfill any legal requirements for written 
communication. 
 </p>
 <p className="terms-content">
 If any provision of this Agreement is found to be invalid, illegal, or unenforceable, it will not 
affect the validity, legality, or enforceability of any other provisions, either in this jurisdiction or 
any other. Our failure to act on a breach of this Agreement by you or others does not waive our 
right to act on future or similar breaches. This Agreement is personal to you and cannot be 
assigned or transferred without our prior written consent. Any unauthorized assignment or 
transfer will be void and without effect. We reserve the right to assign this Agreement and 
delegate any of our obligations under it.
 </p>

    </div>
    <div className="footer">
            <div className="footer-list">
                <ul>
                    <li>Resources</li>
                    <li>Blog</li>
                    <li>Make a Payment</li>
                </ul>
            </div>
            <div className="footer-logo"><img src={AI2Logo}/></div>
            <div className="footer-list">
            <ul>
                    <li onClick={()=>navigate('/Support')}>Support</li>
                    <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                    <li onClick={()=>navigate('/TermsAndConditions')}>Terms and Conditions</li>
                </ul>
            </div>
            </div>
            <div className="disclaimer">© AI2Claim 2024</div>
    </div>
  );
};

export default TermsAndConditions;
