import React, { useEffect, useState } from "react";
import '../Assets/LandingPage.css'
import '../Assets/Howitworks.css'
import '../Assets/pricingPage.css'
import Home from '../Materials/image.png'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import AI2Logo from '../Materials/AI2Logow.png'
import AI2LogoB from '../Materials/AI2Logoq.png'

const PricingPage = () => {
    const [open, setOpen] = useState(false);
    const [highlight,setHighlight] = useState('fencing?')
    const [navBg, setNavBg] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        window.addEventListener('scroll', changeNavColor);
        return () => {
          window.removeEventListener('scroll', changeNavColor);
        }
      }, [])
    const changeNavColor = () =>
        {
         window.scrollY >= 100 ? setNavBg(true) : setNavBg(false);
        }
    React.useEffect(() => {
            window.scrollTo(0, 0)
          }, [])
    return (
        <div>
            <div className="pricing">
            <div className="header-scroll">
            <div className="logo" onClick={()=>navigate('/')}><img style={{height:'9Vh'}} src={AI2Logo} alt='AI2'/></div>
            <nav>
                <ul>
                <li onClick={()=>navigate('/how-it-works')}>How it Works</li>
                <li onClick={()=>navigate('/pricing')}>Pricing</li>
                <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                </ul>
            </nav>
            <button className="login-btn">Login</button>
            </div>
            <div className="pricing-starter">
                <h1>Seamless construction services</h1>
                <p>Without the middleman</p>
            </div>
            </div>
            <div className="pricing-black">
            <h1>Modern pricing for<br/> modern services</h1>
            <p>Coordinate your own job and understand your costs with<br/> three easy line items</p>
            </div>
            <div className="pricing-black-black-next">
            <div className="pricing-black-black-next-items">
              <h1>1. Material Cost</h1>
              <p>View all your options for materials, not just what your local contractor suggests. Understand pricing for your project and get a total.</p>
            </div>
            <div className="pricing-black-black-next-items">
            <h1>2. Labor Cost</h1>
            <p>Choose from highly vetted and insured, local installation crews. See their pricing for your project. Get a total.</p>
            </div>
            <div className="pricing-black-black-next-items">
            <h1>3. Glas Management Cost</h1>
            <p>We charge 15% of your material cost plus your labor cost. No extra or hidden fees. No guessing where we make money. Transparent and simple.</p>
            </div>
            </div>
            <div className="how-it-works-white">
             <div className="how-it-works-white-items">Get to know your benefits</div>
             <div className="how-it-works-white-items"><p>Starting a project is as simple as a few questions. Then we take you through estimating, installation and payment.<br/><button className="how-it-works-white-button">Start your project today &nbsp;&nbsp;<i class="bi bi-arrow-right"></i></button></p></div>
            </div>
            <div className="container-content-pricing"><h1>Transparency<br/> throughout your project</h1></div>
            <div className="container-content">
            <div className="content">
                <h2>Transparent pricing</h2>
                <p>Built in pricing calculators keep you in the know on pricing while selecting every material for your project.</p>
                
                <h2>Know before you sign</h2>
                <p>Start your project, select materials and commit after you see your detailed estimate and project breakdown.</p>
                
                <h2>Predictable and secure payments</h2>
                <p>Only pay just enough to start the project. We store your payment in escrow until the job is completed, ensuring quality and satisfaction.</p>
                
                <button className="cost-button">See your actual costs&nbsp;&nbsp;<i class="bi bi-arrow-right"></i></button>
            </div>            
            <div className="image-container">
                <img src="https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/29f682da-de57-4e49-87f1-fe8e555bd300/accountImages" alt="House with solar panels" />
            </div>
            </div>
            <div className="pricing--sections-container">           
            <div className="pricing--sections">
            <h1>Q&A</h1>
            </div>
            <div className="pricing-collabse-container">
            <>
            <Button
              onClick={() => setOpen(1)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="pricing-collabse-button"
            >
              Glas Platform Fee
            </Button>
            <Collapse in={open==1}>
              <div id="pricing-collabse">
              Glas Platform Fee<br/>
              &nbsp;&nbsp;&nbsp;-Fee will be calculated at 15% of Material + Labor costs on your project, capped at $5,000.<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Example 15%:<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Material Cost $7500 + Labor Cost $5,000 = Glass Platform Fee $1,875.00<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Example 15% Capped at $5k:<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-Material Cost $22,000 + Labor Cost $20,000 = Glass Platform Fee $5,000.00
              </div>
            </Collapse>
            <Button
              onClick={() => setOpen(2)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="pricing-collabse-button"
            >
              Your First Payment
            </Button>
            <Collapse in={open==2}>
              <div id="pricing-collabse">
              First payment amount is determined off of your initial scope. Initial payment will go towards labor costs, material costs, and your Glas Platform fee. Your first payment will be calculated at 100% of labor and material costs plus your Glas Platform fee, not to exceed the first reimbursement check from your insurance provider (Actual Cost Value ie. ACV) listed on the scope of work from your insurance provider that you uploaded in your Glas Account.<br/>
              Order of Payment from Project Trust Account:<br/><br/>
              - Glas Fee<br/>
              - Labor<br/>
              - Materials & Taxes<br/>
              </div>
            </Collapse>
            <Button
              onClick={() => setOpen(3)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="pricing-collabse-button"
            >
              Your Second Payment
            </Button>
            <Collapse in={open==3}>
            <div id="pricing-collabse">
            Remaining balance/ Second Payment to cover any remaining balance on your Material & Labor costs and the Glas Platform Fee that exceeded the value of your first reimbursement check from insurance, plus any change orders agreed upon during the construction phase, will be due within seven (7) business days of receipt of your second reimbursement check (depreciation check) sent from your insurance provider or 45 days following project completion, whichever occurs first.
              </div>
            </Collapse>
            <Button
              onClick={() => setOpen(4)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              className="pricing-collabse-button"
            >
              Payment Facilitation
            </Button>
            <Collapse in={open==4}>
            <div id="pricing-collabse">
            Customer payments are handled by a third-party and require the re-direction of the Customer to the online payment page hosted by that third-party. By clicking "Make My Payment" you are consenting to be redirected to such third-party to take and process your payment, subject to such third-party’s terms and conditions. Your order is not complete until you complete the payment process successfully and are provided an order confirmation from Glas.
              </div>
            </Collapse>
            </>
            </div>
            </div>
            <div className="footer">
            <div className="footer-list">
                <ul>
                    <li>Resources</li>
                    <li>Blog</li>
                    <li>Make a Payment</li>
                </ul>
            </div>
            <div className="footer-logo"><img src={AI2Logo}/></div>
            <div className="footer-list">
            <ul>
                    <li onClick={()=>navigate('/Support')}>Support</li>
                    <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                    <li onClick={()=>navigate('/TermsAndConditions')}>Terms and Conditions</li>
                </ul>
            </div>
            </div>
            <div className="disclaimer">© AI2Claim 2024</div>
        </div>
    )
}

export default PricingPage;