import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage/LandingPage';
import NotFound from './LandingPage/NotFound';
import HowItWorks from './LandingPage/Howitworks';
import PricingPage from './LandingPage/PricingPage';
import ContactUs from './LandingPage/Contact-Us';
import Login from './Components/Login';
import Signup from './Components/Signup';
import Logout from './Components/Logout';
import ProtectedRoute from './Components/ProtectedRoute';
import { useState, useEffect } from 'react';
import Main from './Main/main';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  useEffect(() => {
    console.log("Token changed: ", token);
  }, [token]);

  return (
    <Router>
      <div className="App">
        {/* <Routes>
          <Route path="/" element={<ProtectedRoute element={<LandingPage />} />} />
          <Route path="/login" element={<Login setToken={setToken} />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/logout" element={<Logout setToken={setToken} />} />
          <Route path="/how-it-works" element={<ProtectedRoute element={<HowItWorks />} />} />
          <Route path="/pricing" element={<ProtectedRoute element={<PricingPage />} />} />
          <Route path="/contact-us" element={<ProtectedRoute element={<ContactUs />} />} />
          <Route path="*" element={<NotFound />} />
        </Routes> */}
        <Main/>
      </div>
    </Router>
  );
}

export default App;
