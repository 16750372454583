import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Modal, Navbar, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import '../Assets/LandingPage.css';
import AI2Logo from '../Materials/AI2Logow.png'
import AI2LogoB from '../Materials/AI2Logoq.png'
const NotFound = () => {
  const [navBg, setNavBg] = useState(false);
  const navigate = useNavigate()
    useEffect(() => {
        window.addEventListener('scroll', changeNavColor);
        return () => {
          window.removeEventListener('scroll', changeNavColor);
        }
      }, [])
    const changeNavColor = () =>
        {
         window.scrollY >= 100 ? setNavBg(true) : setNavBg(false);
        }
  return (
    <div className='Land_Page' id='home'>
      <div className={navBg ? "header-scroll":"header"}>
      <div className="logo" onClick={()=>navigate('/')}>{navBg ? <img style={{height:'9Vh'}} src={AI2Logo} alt='AI2'/>:<img src={AI2LogoB} alt='AI2'/>}</div>
            <nav>
                <ul>
                <li onClick={()=>navigate('/how-it-works')}>How it Works</li>
                <li onClick={()=>navigate('/pricing')}>Pricing</li>
                <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                </ul>
            </nav>
            <button className="login-btn">Login</button>
      </div>
      <br /><br /><br />
      <div>
        <Container style={{ color: '#fff', height: '54vh', paddingTop: '3%', background: 'transparent' }}>
          <h1 style={{ color: '#fff' }}>404 - Page Not Found</h1>
          <p>Sorry, the page you are looking for could not be found.</p>
        </Container>
      </div>
      <br /><br />
      <div className="footer">
            <div className="footer-list">
                <ul>
                    <li>Resources</li>
                    <li>Blog</li>
                    <li>Make a Payment</li>
                </ul>
            </div>
            <div className="footer-logo"><img src={AI2Logo}/></div>
            <div className="footer-list">
            <ul>
                    <li onClick={()=>navigate('/Support')}>Support</li>
                    <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                    <li onClick={()=>navigate('/TermsAndConditions')}>Terms and Conditions</li>
                </ul>
            </div>
            </div>
            <div className="disclaimer">© AI2Claim 2024</div><br /><br />
    </div>
  );
};

export default NotFound;