import React, { useEffect, useState } from "react";
import '../Assets/LandingPage.css'
import '../Assets/Howitworks.css'
import '../Assets/pricingPage.css'
import '../Assets/ContactUs.css'
import Home from '../Materials/image.png'
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import AI2Logo from '../Materials/AI2Logow.png'
import AI2LogoB from '../Materials/AI2Logoq.png'

const ContactUs = () =>  {
    const [highlight,setHighlight] = useState('fencing?')
    const [navBg, setNavBg] = useState(false);
    const navigate = useNavigate()
    useEffect(()=>{
     changeText();
    },[])
    
    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const changeText = () => {
        let x = ['gutters?', 'windows?', 'roof?','fencing?'];
        let index = 0;
    
        setInterval(() => {
            setHighlight(x[index]);
            index = (index + 1) % x.length;
        }, 4000);
    };
    useEffect(() => {
        window.addEventListener('scroll', changeNavColor);
        return () => {
          window.removeEventListener('scroll', changeNavColor);
        }
      }, [])
    const changeNavColor = () =>
        {
        }
    return (
        <div>
        <div className="contactus-body">
        <div className="pricing">
     <div className={navBg ? "header-scroll":"header"}>
            <div className="logo" onClick={()=>navigate('/')}>{navBg ? <img style={{height:'9Vh'}} src={AI2LogoB} alt='AI2'/>:<img src={AI2LogoB} alt='AI2'/>}</div>
            <nav>
                <ul>
                <li onClick={()=>navigate('/how-it-works')}>How it Works</li>
                <li onClick={()=>navigate('/pricing')}>Pricing</li>
                <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                </ul>
            </nav>
            <button className="login-btn">Login</button>
            </div> 
           
            </div>
            <div className="contact-white">
             <div className="contact-white-items"><img src='https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/c7209d4c-d5c9-476e-6623-06465de56000/chatImage'/><h4>Chat with Us</h4><p>Live chat with our support and sales teams!</p></div>
             <div className="contact-white-items"><img src='https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/81fa7685-c422-40e0-e9d5-54fbe845ff00/chatImage'/><h4>Contact Us</h4><p>Our customer support staff is ready to answer your questions. Shoot us an email!
             support@ai2claim.com</p></div>
             <div className="contact-white-items"><img src='https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/ca5823cb-c1bd-40ac-0ef7-84b6439a3100/chatImage'/><h4>Contact Sales</h4><p>Ready to start your project? Connect with our sales team and get started today.

sales@ai2claim.com</p></div>
            </div>
            </div>

            <div className="footer">
            <div className="footer-list">
                <ul>
                    <li>Resources</li>
                    <li>Blog</li>
                    <li>Make a Payment</li>
                </ul>
            </div>
            <div className="footer-logo"><img src={AI2Logo}/></div>
            <div className="footer-list">
            <ul>
                    <li onClick={()=>navigate('/Support')}>Support</li>
                    <li onClick={()=>navigate('/contact-us')}>Contact Us</li>
                    <li onClick={()=>navigate('/TermsAndConditions')}>Terms and Conditions</li>
                </ul>
            </div>
            </div>
            <div className="disclaimer">© AI2Claim 2024</div>
            </div>
    )
}

export default ContactUs;